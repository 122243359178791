import ApiFetch from './../Helper/FetchApi';
import { DocumentModelNew, DocumentFormModel } from '../Models/EditorDocumentModel';
import Api from './../Helper/Constant';
const STORAGE_KEY = process.env.REACT_APP_STORAGE_KEY;

let complianceDataList: any = [];
let tempDocumentDataList: any[] = [];
export const getTempDocumentDataList = () => tempDocumentDataList;


// Setter function to modify the data
export const setTempDocumentDataList=(data: any)=>{tempDocumentDataList = data;}

export const getComplianceDataList = () => complianceDataList;
export const setComplianceDataList = (data: any) => { complianceDataList = data; }

/**@GET_SECURITY_QUESTION */
export const getSecurityQuestion = async (isOutSide?: boolean) => {
    return ApiFetch.fetchGet(isOutSide ? Api.getSecurityUser : Api.getSecurity); //
}

/**@DLETE_SECURITY_QUESTION */
export const deleteAllSecurity = async (id: string) => {
    return ApiFetch.fetchDelete(`${Api.deleteAllSecurity}${id}`, []);
}

/**@UPDATE_SECURITY_QUESTION */
export const addAndUpdateSecurityQuestion = async (id: string, reqBody: any) => {
    if (id) {
        console.log(id, 'id');
        return ApiFetch.fetchPut(`${Api.updateSecurity}${id}`, JSON.stringify(reqBody));
    } else {
        return ApiFetch.fetchPost(`${Api.addSecurity}`, JSON.stringify(reqBody));
    }
}

/**@UPDATE_SECURITY_SUB_ITEM_QUESTION */
export const updateSubItems = async (subId: string, reqBody: any, parentId: string) => {
    return ApiFetch.fetchPut(`${Api.updateSecurityNestedItem}${parentId}/${subId}`, JSON.stringify(reqBody));
}

/**@ADD_SECURITY_SUB_ITEM_QUESTION */
export const addSubItems = async (reqBody: any, parentId: string,) => {
    return ApiFetch.fetchPost(`${Api.addNewSecurityNestedItem}${parentId}`, JSON.stringify(reqBody));
}

/**@DELETE_SECURITY_SUB_ITEM_QUESTION */
export const deleteSubItems = async (mainId: any, subId: string,) => {
    return ApiFetch.fetchDelete(`${Api.deleteSecurityNestedItem}${mainId}/${subId}`, JSON.stringify({}));
}


/**@GET_SECURITY_QUESTION */
export const getOverview = async (isOutSide?: boolean) => {
    return ApiFetch.fetchGet(Api.getOverview);
}

/**@GET_SECURITY_QUESTION */
export const getOverviewUser = async () => {
    return ApiFetch.fetchGet(Api.getOverviewUser);
}


/**@UPDATE_SECURITY_QUESTION */
export const addAndUpdateOverview = async (id: string, reqBody: any) => {
    if (id) {
        return ApiFetch.fetchPut(`${Api.updateOverview}${id}`, JSON.stringify(reqBody));
    } else {
        return ApiFetch.fetchPost(`${Api.addOverview}`, JSON.stringify(reqBody));
    }

}

/**@GET_COMPLIANCE */
export const getComplianceData = async (isOutSide?: boolean) => {
    return ApiFetch.fetchGet(isOutSide ? Api.getCompliancUser : Api.getCompliance);
}

/**@UPDATE_COMPLIANCE */
export const addAndUpdateCompliance = async (id: string, reqBody: any) => {
    if (id) {
        return ApiFetch.fetchPut(`${Api.updateCompliance}${id}`, JSON.stringify(reqBody));
    } else {
        return ApiFetch.fetchPost(`${Api.addCompliance}`, JSON.stringify(reqBody));
    }
}

/**@DLETE_COMPLIANCE */
export const deleteCompliance = async (id: string) => {
    return ApiFetch.fetchDelete(`${Api.deleteCompliance}${id}`, []);
}

/**@UPDATE_COMPLIANCE */
export const addAndUpdateAccounts = async (id: string, reqBody: any) => {

    return ApiFetch.fetchPost(`${Api.addAccounts}`, JSON.stringify(reqBody));

}

/**@CHECK_BY_EMAIL_ACCESS */
export const userAccessRequest = async (reqBody: any) => {
    return ApiFetch.fetchPost(`${Api.userAccessRequestApi}`, JSON.stringify(reqBody));
}

/**@CHECK_BY_EMAIL_ACCESS_Check */
export const userAccessCheck = async () => {
    try {
        const permissionToken: any = JSON.parse(localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/fileAccess`) || '{}');
        const result = await ApiFetch.fetchPost(`${Api.userAccessCheckApi}`, JSON.stringify({ permissionToken }));
        if (result && result.status) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

/**@CHECK_BY_EMAIL_ACCESS (this is original code) */
export const checkFileAccessPermission = async () => {
    const data:any=JSON.parse(sessionStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/fileAccess`) || '{}');
    if(data && data.hasOwnProperty('time') && data.hasOwnProperty('token')){
        if(data.time < new Date().getTime()){
            return true;
        }else{
            return false;
        }
    }else{
        return false;
    }
}


// /**@CHECK_BY_EMAIL_ACCESS */
// export const checkFileAccessPermission = async () => {
//     try {
//         const fileAccess: any = JSON.parse(sessionStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/authData`) || '{}');

//         if (true) {
//             return true;
//         } else {
//             return false;
//         }
//     } catch (error) {
//         return false;
//     }
// }

export const getDocsList = async (isOutSide?: boolean): Promise<DocumentModelNew[]> => {
    return ApiFetch.fetchGet((isOutSide ? Api.getDocumentsListUser : Api.documentsList));
};


export const getNewDocListForBulkdownload = async (): Promise<DocumentModelNew[]> => {
    let dbNameToken: any = localStorage.getItem(`${STORAGE_KEY}/dbName`);
    try {
        dbNameToken = dbNameToken.replace(/["]/g, '')
    } catch (e) {
        dbNameToken = window.location.hostname;
    }

    const _token: any = await localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/fileAccess`) || "";
    let token:any="";
    if(_token){
        token=JSON.parse(_token);
        token=token.token;
    }
    const headers: any = { 'Content-Type': 'application/json', 'Accept': 'application/json, text/plain, */*' , 'DbName': `Bearer "${dbNameToken}"`,};
    console.log(token, 'token');
    
    if(token) headers['Authorization']=`Bearer ${token}`;
    const option: any = { method: 'GET', headers: headers};
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}${Api.newBulkdownloaduser}`, option);
    return await response.json();

};

/**@UPDATE_SECURITY_QUESTION */
export const addAndUpdateDocuments = async (id: string, reqBody: any) => {
    if (id) {
        return ApiFetch.fetchPut(`${Api.updateDocuments}${id}`, JSON.stringify(reqBody));
    } else {
        return ApiFetch.fetchPost(`${Api.addDocuments}`, JSON.stringify(reqBody));
    }
}

/**@DLETE_COMPLIANCE */
export const deleteDocument = async (id: string) => {
    return ApiFetch.fetchDelete(`${Api.updateDelete}${id}`, []);
}

/**@DOWNLOAD_LOGS */
type Type = "editor" | "user";
export const createDownloadLogs = async (fileName: string[], screenType: string, type: Type, isPublic: boolean) => {
    const obj = {
        "screenType": screenType,
        "fileName": fileName,
        "type": type
    }

    let token: any = await localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/token`);
    if (!token) {
        token = await localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/fileAccess`)
    }
    const headers: any = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`, 'Accept': 'application/json, text/plain, */*' };
    if (isPublic && !token) {
        delete headers.Authorization;
    }
    const option: any = { method: 'POST', headers: headers, body: JSON.stringify(obj) };
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}${Api.trackDownloadReq}`, option);
    // const statusCode = response.status;
    // const data = await response.json();
}

// /**@BULK_DOWNLOAD */
// export const bulkDownload = async (reqBody: any) => {
//     try {
//         let fileName: string = new Date().getTime() + '_docs.zip';
//         const response = await ApiFetch.fetchPostFlask(`${Api.bulkDownload}`,JSON.stringify(reqBody));

//         const blob = await response.blob();
//         const url = window.URL.createObjectURL(blob);
//         const a = document.createElement('a');
//         a.style.display = 'none';
//         a.href = url;
//         a.download = fileName
//         document.body.appendChild(a);
//         a.click();
//         window.URL.revokeObjectURL(url);
//         return Promise.all([response.status, blob]).then(res => ({
//             statusCode: res[0],
//             ...res[1]
//         }));
//    } catch (error) {
//         return Promise.all([404, []]).then(res => ({
//             statusCode: res[0],
//             ...res[1]
//         }));
//    }
// }

/**@BULK_DOWNLOAD */
export const bulkDownload = async (reqBody: any) => {
    try {
        
        let dbNameToken: any = localStorage.getItem(`${STORAGE_KEY}/dbName`);
        try {
            dbNameToken = dbNameToken.replace(/["]/g, '')
        } catch (e) {
            dbNameToken = window.location.hostname;
        }

        const option: any = { method: 'POST', body: JSON.stringify(reqBody), headers: { 'Content-Type': 'application/json', 'DbName': dbNameToken } };
        const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}${Api.bulkDownload}`, option);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = "Trust_Center_Bulk_Download.zip"
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        return Promise.all([response.status, blob]).then(res => ({
            statusCode: res[0],
            ...res[1]
        }));
    } catch (error) {
        return Promise.all([404, []]).then(res => ({
            statusCode: res[0],
            ...res[1]
        }));
    }
}

/**@BULK_DELETE */
export const bulkDelete = async (files: any[], bucketName: string) => {
    try {
        let dbNameToken: any = localStorage.getItem(`${STORAGE_KEY}/dbName`);
        dbNameToken = dbNameToken.replace(/["]/g, '')
        const fileKeys: string[] = files.map((res: any) => res._id);
        const reqBody = {
            fileKeys: fileKeys,
            bucketName: bucketName
        }
        console.log(reqBody, 'reqBody');
        if (fileKeys.length == 0) {
            return Promise.all([201, []]).then(res => ({
                statusCode: res[0],
                ...res[1]
            }));
        }
        const option: any = { method: 'POST', body: JSON.stringify(reqBody), headers: { 'Content-Type': 'application/json', 'DbName': dbNameToken } };
        const response = await fetch(`${process.env.REACT_APP_FLASK_SERVER_URL}${Api.bulkDelete}`, option);
        const data = await response.json();
        return Promise.all([response.status, data]).then(res => ({
            statusCode: res[0],
            ...res[1]
        }));
    } catch (error) {
        return Promise.all([201, []]).then(res => ({
            statusCode: res[0],
            ...res[1]
        }));
    }
}

/**@UPDATE_DOCS */
export const getLoginUserAccess = async () => {
    return await localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/token`)
}

/**@UPDATE_DOCS */
export const addAndUpdateDocumentFiles = async (id: string, reqBody: any) => {
    if (id) {
        return ApiFetch.fetchPut(`${Api.updateDocuments}${id}`, JSON.stringify(reqBody));
    } else {
        return ApiFetch.fetchPost(`${Api.addDocumentFile}`, JSON.stringify(reqBody));
    }
}

type Props = 'compliance' | 'overview' | 'Security Card';
export const getHistoryList = (page: number, pageSize: number, type: Props) => {
    return ApiFetch.fetchGet(`${Api.tcHistroy}?page=${page}&pageSize=${pageSize}&pageType=${type}`);
}

/**@SAVE_HISTORY */
export const saveHistory = async (reqBody: any) => {
    return ApiFetch.fetchPost(`${Api.tcHistroySave}`, JSON.stringify(reqBody));
}

/** @GER_AUTH_INFO */
export const getAuth = () => {
    const auth: any = localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/authData`);
    return JSON.parse(auth || '{}');
}

/** @getCheckSpecialDomain */
export const getCheckSpecialDomain = async(hostName:string): Promise<boolean> => {
    const s3Url = process.env.REACT_APP_FLASK_SERVER_URL;
    
    const response = await fetch(`${s3Url}/check-hostname?hostname=${hostName}`);
    const data = await response.json();
    return data && data?.isSpecialDomain ? true : false;
}