
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import EnterpriseService from '../../../ApiServices/Enterprise.Service';
import { useState } from 'react';
import { getCompanyList } from "../../../Redux/Actions/SagaAction";
import { Controller, useForm } from 'react-hook-form';
import { CompanysModal } from '../../../Models/Companys';


function AddVendor(props: any) {
    const [isLoading, setLoading] = useState(false);
    const clientDetails = useSelector((state: any) => state.AppReducer.clientDetails);
    // const companyList = useSelector((state: any) => state.AppReducer.companyList);
    const [companyError, setCompanyError] = useState<any>("");
    const dispatch = useDispatch();
    const { control, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm<CompanysModal>({
        defaultValues: { companyName: '' }
    });

    // save companys
    const saveCompanyName = async (e: any) => {
        // Trim the companyName property
        e.companyName = e.companyName.trim();
        const obj: any = {
            companyName: e.companyName.trim(),
            compliance_bucket: clientDetails?.complianceBucket,
            questionnaires_bucket: clientDetails?.questionnairesBucket
        }
        if (!obj?.compliance_bucket || !obj?.questionnaires_bucket) {
            return;
        }
        setLoading(true);
        const res = await EnterpriseService.saveCompanys(obj);
        if (res) {
            if (res && res?.data && res.data?._id) {
                EnterpriseService.setCompnayInfo = res?.data;
                console.log("Company Data on add vendor");
                if(props?.onChangeCompany) props?.onChangeCompany(res.data._id);
            } else if (res?.message) {
                setCompanyError(res?.message);
            }
            dispatch(getCompanyList());
            setLoading(false);
        } else {
            setLoading(false);
        }
    }



    // BUCKET_NAME_VALIDATION
    function validateS3BucketName(companyName: string) {
        // Check for leading or trailing whitespace
        if (companyName !== companyName.trim()) {
            return "Company name cannot have leading or trailing whitespace.";
        }

        if (companyName.length < 3 || companyName.length > 63) {
            return "Company name must be between 3 and 63 characters.";
        }

        const allowedCharacters = /^[A-Za-z0-9 ]+$/;
        if (!allowedCharacters.test(companyName)) {
            return "Company name can only contain alphabate, numbers, and spaces.";
        }
        return null;
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>Create New Vendor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row mt-4'>
                    <div className="col-md-8 offset-md-2 mb-5">
                        <form autoComplete={'off'} onSubmit={handleSubmit(saveCompanyName)}>
                            <div>
                                <label htmlFor="" style={{ paddingBottom: 5 }}>Company name</label>
                                <Controller
                                    name="companyName"
                                    control={control}
                                    rules={{
                                        required: 'This field is required',
                                        validate: value => validateS3BucketName(value) || true
                                    }}
                                    render={({ field }) => <input type="text" autoComplete="off" className="form-control" {...field} />}
                                />
                                {errors.companyName && (
                                    <div className="sc-input-err-msg">{errors.companyName?.message}</div>
                                )}
                                {!errors?.companyName && companyError != "" ? (
                                    <div className="sc-input-err-msg capitalize-first-letter">{companyError}</div>
                                ) : null}
                            </div>
                            <br />
                            <div className="text-center">
                                <button disabled={isLoading ? true : false} className='btn btn-outline-secondary ms-auto px-4'>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
            {/* onClick={props.onHide} */}
        </Modal>
    );
}

export default AddVendor;
