import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Signupconfirmation.css';
import useToken from '../../ApiServices/useToken';
import Loading from '../../Components/LoadingAnimation/index';
import { getSettings, isAuthenticated } from '../../ApiServices/SettingsApi';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastManager } from '../../Components/ToastDialog/ToastManager';
import {  updateProvisionedStatus, fetchS3Buckets } from '../../ApiServices/ValidationApis';
import CheckMark from '../../Components/CheckMark';
import { useLocation } from 'react-router-dom';


const SignUpConfirmation: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true); // Set loading to true initially
  const [provisioned, setProvisioned] = useState(false); // New state to track provisioning success

 
  useEffect(() => {
    const provisionInfra = async () => {
      
      
  

    };

    provisionInfra();
  }, []);
  
  const getTokenUserData = async (): Promise<any> => {
    try {
        const tokenUserData: any = localStorage.getItem(`${process.env.REACT_APP_STORAGE_KEY}/authData`);
        const email = JSON.parse(tokenUserData).email
        return email;
    } catch (error) {
        console.error('Error retrieving token user data:', error);
        throw error;
    }
};

  return (
    
      <div className="homepage-containerr">
          <img src={process.env.PUBLIC_URL + 'assets/imgs/watermark.png'} alt="watermark" className="watermark" />
          <div className="homepage-sectionn">
              <img src={process.env.PUBLIC_URL + 'assets/imgs/logo.png'} alt="Targhee security" className="logo-img" />
              <div className="provision">
              <div className="provision-container">
              <p className='screen-title'>Thanks for signing up!</p>
              <p className='screen-titlee'>We will review your request and get back to you as soon as possible.</p>
          
              <div className="loadinggg">
                
                <CheckMark height={40} width={40} color={'success'} refresh={true}  />
              </div>
            
            
            
            </div>

              </div>
          </div>    
      </div>
  );

}

export default SignUpConfirmation;