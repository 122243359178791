const Api = {
    /**@TRUST_CENTER_OUTSIDE */
    getOverviewUser: `/overviewuser`,
    getCompliancUser: `/complianceuser`,
    getDocumentsListUser: `/documentslistuser`,
    getSecurityUser: `/securityuser`,  /// for
    trackDownloadReq: `/trackdownloadreq`,

    /**@HISTORY */
    tcHistroySave: `/trustcenterhistroy/save`,
    tcHistroy: `/trustcenterhistroy`,

    /**@SECURITY */
    getSecurity: `/security`,
    addSecurity: `/security/save`,
    updateSecurity: `/security/update/${''}`,
    deleteAllSecurity: `/security/delete/${''}`,
    updateSecurityNestedItem: `/security/subdata/${''}${''}`,/**@TWO_LAVEL_PARAMS_ID security/subdata/$mainid/$id */
    addNewSecurityNestedItem: `/security/addsubdata/${''}`,/**@ADD_SUBDATA security/subdata/$id/$id */
    deleteSecurityNestedItem: `/security/delete/${''}${''}`,/**@ADD_SUBDATA security/subdata/$mainId/$SubId */


    /**@OVERVIEW */
    getOverview: `/overview`,
    addOverview: `/overview/save`,
    updateOverview: `/overview/update/${''}`,

    /**@COMPLIANCE */
    getCompliance: `/compliance`,
    addCompliance: `/compliance/save`,
    updateCompliance: `/compliance/update/${''}`,
    deleteCompliance: `/compliance/delete/${''}`,

    /**@DOCUMENT */
    getDocuments: `/documents`,
    addDocuments: `/documents/save`,
    addDocumentFile: `/documents/saveFile`,
    updateDocuments: `/documents/update/${''}`,
    updateDelete: `/documents/delete/${''}`,
    documentsList: `/documentslist`,




    bulkDownload: `/bulk-download-docs`,
    newBulkdownloaduser:`/bulkdownloaduser`,
    bulkDelete: `/delete-files`,

    /**@ACCOUNTS */
    getAccounts: `/accountsrequest`,
    addAccounts: `/accounts/save`,
    addAccountsFile: `/accounts/saveFile`,
    updateAccounts: `/accounts/update/${''}`,
    accountsDelete: `/accounts/delete/${''}`,
    accountStatusApi: `/accountsapprove/${''}`,

    userAccessRequestApi: `/useraccessrequest`, //check email access for trust center
    userAccessCheckApi: `/useraccesscheck`,

    /**@USER_REGISTER */
    useCreate: `/usercreate`,


    /**@USER_PROFILE */
    userProfile: `/userprofile/${''}`,
    updateUserProfile: `/user/update/${''}`,

    /**@GET_SETTING */
    getSettings: `/settings`,
    getSettingsOutside: `/settingsOutside`,

    /**@GET_ClientDetails*/
    getclientDetails: `/clientDetails`,
    getclientDetailsOutside: `/clientDetailsOutside`,

    /**@POST_DomainStatus  */
    saveDomainStatus: `/domainnamestatus`,

    /**@POST_SETTING */
    saveSettings: `/settings/save`,
    updateSettings: `/settings/update`,

    /**@POST_FORGOT_PASSWORD */
    userForgotPassword: `/userforgotpassword`,
    userOtpValidation: `/userotpvalidation`,
    userPasswordChange: `/userpasswordchange`,

    /**@PEOPPLE */
    peopleList: `/peoplelist`,
    peopleInvite: `/people/invite`,
    peopleDelete: `/people/delete/${''}`,
    peopleInviteSignUp: `/people/invitechecksignup`,
    peopleCreateSignUp: `/usercreate/people`,

    /**@POST_GET_COMPANIES */
    getCompanys: `/companys`,
    saveCompanys: `/companys/save`,
    deleteCompanys: `/companys/delete`,

    /**@PUT_GET_COMPANIES_Compliancefile */
    getCompliancefile: `/compliancefile/${''}`,
    compliancefileUpdate: `/compliancefile/update/${''}`,

    /**@PUT_GET_COMPLIANCE_FILE_ENTERPRISE */
    complianceFileSave: `/compliancefile/save`,
    questionnairesList: `/questionnaires`,
    questionnairesSave: `/questionnaires/save`,
    updateEnterpriseAnswer: `/questionnaires/question/update`,
    getComment: `/comments/${''}`,
    getCountOfQuestion: `/getquestionlist/${''}`,
    downloadQuestion: `/downloadquestions/${''}`,
    llmQuestions: `/llm-questions`,
    llmQuestionsAnswers: `/llm-questions-answers`,
    testCollections: `/test-collections`,
    genarateAnswerUsingLlm: `/get-llm-answer-of-questions`,
    complianceFileDelete: `/compliancefile/delete/${''}`,
    questionnairesDelete: `/questionnaires/delete/${''}`,
    enterpriseS3bucketDeleteFile: `/enterpriseapps-s3bucket-deletefile`,
    enterpriseDeletePinecodeData: `/enterpriseapps-compliance-pineconedata-delete`,
 


   /**@NDA_FILE */
    saveNda :'/nda/save',
    updateNda: `/nda/update${''}`,
    getNda: `/ndalist`,
    getoutsideNda: `/ndauser`,


   /**@THEME_COLORS */
    getAllThemeColors :`/themecolor`,
    saveThemeColors :`/themecolor/save`,
    updateThemeColors :`/themecolor/update/${''}`

};

export default Api;