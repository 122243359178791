export interface ActionProps {
    type: string;
    payload: any;
}

export interface InitialStateProps {
    error: string;
    redirect: string;
    isLoading: false;
    companyList: any[];
    complianceArrayList: ComplianceQuestionModels[];
    questionArrayList: QuestionModels[];
    selectedQuestion: SelectedFileModels;
    authData: AuthDataModels;
    securityList: any[];
    tCenterComplianceList: any[];
    isSpecialDomain: string;
    clientDetails:ClientDetailsModel,
    countReviewItem:CountReviewModel,
    themeColors:themeColorsModel,
    peopleList:PeopleListModel[],
    accountList:AccountListModel[],
    approvedAccountList:AccountListModel[],
    overview:OverviewModel,
    complianceData:ComplianceDataModel[],
    documents:DocumentsDataModel[],
    securityData:SecurityDataModel[],
}

export interface ComplianceQuestionModels {
    _id: string;
    company_id: string;
    files: File[];
    createdAt?: Date;
    updatedAt?: Date;
    fileId?: string;
}


export interface QuestionModels {
    _id: string;
    questions: FileQuestionModels[];
    company_id: string;
    files: File[];
    createdAt?: Date;
    updatedAt?: Date;
    fileId?: string;
}

export interface SelectedFileModels {
    questions: FileQuestionModels[];
    company_id: string;
    fileId?: string;
    _id: string;
    createdAt?: Date;
    fileName: string;
}

export interface FileQuestionModels {
    question: string;
    answer: string;
    status: string;
    user_id: null;
    _id: string;
    createdAt: Date;
    updatedAt: Date;
    llmstatus: boolean;
    reviewstatus: boolean;
    llmReviewRespons: boolean;
    answerHistory:any[]
}

export interface File {
    fileName: string;
    _id: string;
}

export interface AuthDataModels {
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    password?: string;
    dbName?: string;
    status?: boolean;
    admin?: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    token?: string;
    otp?: string;
    otpExpires?: Date;
}

export interface ClientDetailsModel {
    domainPublish?:        string;
    _id:                  string;
    database?:             string;
    cname?:                string;
    cnamepublish?:         boolean;
    maindomain:           string;
    complianceBucket:     string;
    questionnairesBucket: string;
    lastModified?:         Date;
    fileStorage?:          string;
}

export interface CountReviewModel{
    countOfApproved:number;
    countOfReview:number;
}

export interface themeColorsModel{
    _id:string;
    headerColor:string;
    backgroundColor:string;
    textColor:string;
    buttonColor:string;
}

export const defaultThemeColors={
    _id:'',
    headerColor:'#3c3232',
    backgroundColor:'#3c3232',
    textColor:'#000000',
    buttonColor:'#0a66c2'
}

export interface PeopleListModel {
    _id:       string;
    email:     string;
    status:    string;
    Approved:  string;
    createdAt: Date;
    updatedAt: Date;
}

export interface AccountListModel {
    _id:                 string;
    firstName:           string;
    lastName:            string;
    jobTitle:            string;
    workEmail:           string;
    companyName:         string;
    relationshpLinkedIn: string;
    countryRegion:       string;
    status:              boolean;
    isDeleted:           boolean;
    Approved:            string;
    getUpdates:          boolean;
    termsOfservice:      boolean;
    createdAt:           Date;
    updatedAt:           Date;
}

export interface OverviewModel {
    _id:         string;
    subTitle:    string;
    description: string;
}


export interface ComplianceDataModel {
    _id:         string;
    subTitle:    string;
    description: string;
    isPublic:    boolean;
    icon:        string;
    files:       File[];
    createdAt:   Date;
    updatedAt:   Date;
}

export interface DocumentsDataModel{
    _id: string;
    documentName: string;
    isPublic: boolean;
    description: string;
    files: Items[];
}


export interface Items{
    _id: string;
    documentName: string;
    isPublic:     boolean;
    description:  string;
    files:        any[]; 
}

export interface SecurityDataModel {
    _id:       string;
    mainTitle: string;
    subData:   SubDataModel[];
    createdAt: Date;
    updatedAt: Date;
    __v:       number;
}

export interface SubDataModel {
    title:       string;
    description: string;
    isPublic:    boolean;
    files:       File[];
    _id:         string;
}