import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "./FilesList.css";
import EnterpriseService from '../../../ApiServices/Enterprise.Service';
import { S3UploadComplaince_Enterpriseapps, S3UploadQuestionnaire_Enterpriseapps } from '../../../ApiServices/S3Bucket';
import { Col, Row } from 'react-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmationManager } from './../../../Components/ConfirmationDialog/ConfirmationManager';
import { setEnterpriseFileLoading, removeEnterpriseItemFromList } from "./../../../Redux/Actions/SagaAction";
import Modal from 'react-bootstrap/Modal';
export interface NotificationModel {
    type: 'success' | 'error' | 'none';
    msg: string;
}

// import { toastManager } from './../../../Components/ToastDialog/ToastManager'; 
// complianceFileSave, formDataFileSave

interface FilesListProps {
    listType: string;
    companyId: string;
    files: any[]; // Add the FileItem type to the files property
    refresh: () => void;
    handleShowLoadingPage: (file: any, listType: string) => void;
    fileProcessShowLoader: (file: any) => void;
    onClick: () => void;
    companyName?: string;
}

const FilesList = (props: FilesListProps) => {
    const [errorModal, setErrorModalShow] = React.useState(false);
    const [fileName, setFileName] = useState<string>('');
    const selectedQuestion = useSelector((state: any) => state.AppReducer.selectedQuestion);
    const companyList = useSelector((state: any) => state.AppReducer.companyList);
    const clientDetails = useSelector((state: any) => state.AppReducer.clientDetails);
    const dispatch: any = useDispatch();
    let title = '';
    let uploadText = '';

    if (props.listType === "documents") {
        title = "Compliance";
        uploadText = "Knowledgebase";
    } else {
        title = "Questionnaire";
        uploadText = " AI Complete ";
    }

    useEffect(() => {
        console.log(props.files, 'props.files');
    }, [props.companyName])


    /**
     * Handles the file upload process, including saving file metadata and uploading the file to S3.
     * @param event - The change event from the file input.
    */
    const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const getfile = event.target.files[0]; // Get the first file

            // Remove spaces from the original file name
            const updatedFileName = getfile.name.replace(/\s+/g, '');
            // Create a new File object with the updated name and the same content
            const file: any = new File([getfile], updatedFileName, {
                type: getfile.type, // Keep the original file type
                lastModified: getfile.lastModified // Preserve the last modified date
            });

            //const file: any = event.target.files[0];
            const companyId = EnterpriseService.getCompanyInfo?._id || selectedQuestion.company_id;
            const questionnairesFolder = EnterpriseService.getCompanyInfo?.questionnairesFolder;
            const complianceFolder = EnterpriseService.getCompanyInfo?.complianceFolder;
            const companyName = EnterpriseService.getCompanyInfo?.companyName;
            const getdata: any[] = companyList.filter((item: any) => item._id == companyId);

            const reqObj: any = {
                company_id: companyId,
                files: [{ fileName: file?.name }],
            }


            setFileName(file?.name || '');
            if (props.listType === "documents") {
                console.log('AAAA', JSON.stringify(getdata));
                // if (getdata && getdata.length) {
                //     bucketId = getdata[0]?.complianceFolder;
                // }
                dispatch(setEnterpriseFileLoading({
                    "company_id": companyId,
                    "files": [{ "fileName": file?.name }],
                    "action": "documents"
                }))
                props.fileProcessShowLoader({ item: [], itemType: 'documents', modelShow: true, title: 'Compliance File Inprocess!', message: '', checkmark: false });
                const result = await EnterpriseService.complianceFileSave(reqObj);

                //props.handleShowLoadingPage({item:event.target.files[0], itemType:'documents'});
                if (result && result.status == true) {
                    S3UploadComplaince_Enterpriseapps({ companyId: companyId, file: file, fileId: result?.data?.files[0]?._id, vendorName: companyName, bucketName: clientDetails.complianceBucket, folderName: complianceFolder }).then((res: any) => {
                        props.refresh();
                        props.fileProcessShowLoader({ item: file, itemType: 'documents', modelShow: true, title: 'Compliance File Uploaded!', message: 'file uploaded successfully', checkmark: true });
                    }).catch((err: any) => {
                        console.log(err, 'err file');
                        dispatch(setEnterpriseFileLoading({
                            "company_id": companyId,
                            "files": [{ "fileName": file?.name }],
                            "action": "remove-from-documents-list"
                        }))
                        EnterpriseService.deleteEnterpriceFiles(result?.data?._id, 'documents');
                        EnterpriseService.enterpriseS3bucketDeleteFile({ bucketName: clientDetails.complianceBucket, folderName: complianceFolder, fileName: file?.name });
                        props.fileProcessShowLoader({ item: [], itemType: 'documents', modelShow: true, title: 'Failed!', message: 'file upload failed', checkmark: false });
                        setErrorModalShow(true)
                    })
                }
            } else {

                const formData = new FormData();
                formData.append('company_id', (props?.companyId || companyId));
                formData.append('files', file);
                formData.append('vendor_name', companyName);
                props.fileProcessShowLoader({ item: [], itemType: 'questions', modelShow: true, title: 'Questionnaire File Inprocess!', message: '', checkmark: false });
                dispatch(setEnterpriseFileLoading({
                    "company_id": companyId,
                    "files": [{ "fileName": file?.name }],
                    "action": "questions"

                }))
                const result = await EnterpriseService.formDataFileSave(formData);
                //props.handleShowLoadingPage({item:event.target.files[0], itemType:'questions'});
                if (result && result.status == true) {
                    sessionStorage.setItem('tempData', JSON.stringify(result.data.files))
                    S3UploadQuestionnaire_Enterpriseapps({ file: file, fileId: result?.data?.files[0]?._id, bucketName: clientDetails.questionnairesBucket, folderName: questionnairesFolder }).then((res: any) => {
                        props.refresh();
                        props.fileProcessShowLoader({ item: file, itemType: 'questions', modelShow: true, title: 'Questionnaire File Uploaded!', message: 'file uploaded successfully', checkmark: true });
                    }).catch((err: any) => {
                        console.log(err, 'err file');

                        dispatch(setEnterpriseFileLoading({
                            "company_id": companyId,
                            "files": [{ "fileName": file?.name }],
                            "action": "remove-from-questions-list"
                        }))
                        EnterpriseService.deleteEnterpriceFiles(result?.data?._id, 'questions');
                        EnterpriseService.enterpriseS3bucketDeleteFile({ bucketName: clientDetails.questionnairesBucket, folderName: questionnairesFolder, fileName: file?.name });
                        props.fileProcessShowLoader({ item: [], itemType: 'questions', modelShow: true, title: 'Questionnaire File Inprocess!', message: 'file upload failed', checkmark: false });
                        setErrorModalShow(true)
                    })
                }
            }
        }

        event.target.value = ""; // Reset the input value
    }

    /**
     * Handles the action of opening a file item based on the specified title.
     * @param item - The file item to be opened.
     * @param index - The index of the item in the list.
    */
    const onOpenFile = (item: any, index: number) => {
        // if(title=='Questionnaire'){
        //     console.log(item, 'item');
        //     props.handleShowLoadingPage(item, props.listType);
        // }

        if (getFileType(item['files'][0]['fileName']) == 'pdf') {
            props.handleShowLoadingPage(item, 'only-doument-download');
        } else {
            props.handleShowLoadingPage({ item: item, itemType: props.listType }, props.listType);
        }
    }

    /**
     * @GET_FILE_NAME
    */
    const getFileType = (filename: any) => {
        return filename.split('.').pop();
    }

    /**@DELETE_FIELS */
    const deleteFiles = (item: any) => {
        console.log(item, 'item dekeye');

        confirmationManager.requestConfirmation({
            heading: "Confirm Deletion",
            message: 'Are you sure you want to delete this item?',
            onConfirm: async () => {
                const questionnairesFolder = EnterpriseService.getCompanyInfo?.questionnairesFolder;
                const complianceFolder = EnterpriseService.getCompanyInfo?.complianceFolder;
                dispatch(removeEnterpriseItemFromList({
                    "id": item?._id,
                    "action": props.listType
                }))
                if (props.listType === "documents") {
                    EnterpriseService.deleteEnterpriceFiles(item._id, 'documents');
                    EnterpriseService.enterpriseS3bucketDeleteFile({ bucketName: clientDetails.complianceBucket, folderName: complianceFolder, fileName: item?.files[0]?.fileName });
                    EnterpriseService.enterprisePDfDelete_Chunk_Pinecone({ namespace: props.companyName, id_prefix: item?.files[0]?.fileName });
                } else {
                    EnterpriseService.deleteEnterpriceFiles(item._id, 'questions');
                    EnterpriseService.enterpriseS3bucketDeleteFile({ bucketName: clientDetails.questionnairesBucket, folderName: questionnairesFolder, fileName: item?.files[0]?.fileName });
                }
            },
            onCancel: () => {
                console.log('Delete canceled');
            },
        });
    };

    return (
        <div className="files-container">
            <h2>{title}</h2>
            <div className="list-container" style={{ marginLeft: '35px' }}>
                <Row style={{ flex: 'auto' }} className='row-style'>
                    {props.files.map((item: any, index: number) => (
                        <Col key={`list-item-${index}-${item._id}`} sm={6} md={4} lg={4} xl={3} xs={12}>
                            <div className="list-item complinitem position-relative">
                                {item?.action ? null : <span onClick={() => deleteFiles(item)} className={"position-absolute ent-delete-file"}>
                                    <FontAwesomeIcon icon={faXmark} color={'red'} />
                                </span>}
                                <div onClick={() => onOpenFile(item, index)} className="icon-container w-100 text-center pointer">
                                    <img src={`${process.env.PUBLIC_URL}${(getFileType(item['files'][0]['fileName']) == 'pdf') ? '/assets/imgs/pdf.png' : '/assets/imgs/docIcon.png'}`} alt="file-icon" style={{ width: 120, padding: 5 }} />
                                </div>
                                <p className="w-100 text-center ellipsis-2l" style={{ minWidth: 70 }}>{item['files'][0]['fileName']}</p>
                                <div className={'w-100 d-flex justify-content-center'} style={{ height: 20 }}>
                                    {item?.action ? <img
                                        style={{ opacity: 0.4, height: 20, width: 20, marginTop: -16 }}
                                        src={`${process.env.PUBLIC_URL}/assets/imgs/ios-loading.gif`} /> : null}
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
                <div className="list-item align-items-center" style={{ marginLeft: '32px', flex: 1.5, maxWidth: '160px', minWidth: 160, width: 160 }}>
                    <label htmlFor={`file-${props.listType}`} style={{ cursor: 'grab' }}>
                        <div className="icon-container">
                            <img src={process.env.PUBLIC_URL + 'assets/imgs/upload-icon.png'} alt="upload-icon" style={{ width: 64, marginBottom: '12px' }} />
                        </div>
                        <div className="upload-text-container">
                            <p style={{ overflow: 'hidden' }}>{uploadText}</p>
                        </div>
                        <input id={`file-${props.listType}`} type="file" accept={(props.listType === "documents" ? 'application/pdf' : 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')} onChange={onFileUpload} style={{ display: 'none' }} />
                    </label>
                </div>
            </div>

            <Modal size={'lg'} centered show={errorModal}>
                <Modal.Body className='error-popup'>
                    <span style={{ position: 'absolute', right: 15, top: 10 }} className='pointer' onClick={() => setErrorModalShow(false)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </span>
                    <h3><b>Error!</b></h3>
                    <p>Oops! Something went wrong with your file upload. Please double-check your PDF format and make sure all the content is intact and correctly formatted. Give it another try!</p>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default FilesList;