import { configureStore } from "@reduxjs/toolkit";
import { Buffer } from "buffer";
Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;
const STORAGE_KEY = process.env.REACT_APP_STORAGE_KEY;

interface Props {
    file: any,
    bucketName: 'compliance-docs-targhee' | 'mytest1-docs',
    fileId: string
    folderName:string
}

const S3Upload = ({ file, fileId, bucketName }: Props) => {
    console.log("Upload triggered Upload triggered Upload triggered");
    return new Promise(async function (resolve, reject) {
        const formData: any = new FormData();
        formData.append("file", file);
        formData.append("fileId", fileId);
        console.log("Upload triggeredb");
        try {
            const url: string = `${process.env.REACT_APP_FLASK_SERVER_URL}/upload-docs`;
            let dbNameToken: any = localStorage.getItem(`${STORAGE_KEY}/dbName`);
            console.log("dbNameToken1 upload", dbNameToken);
            dbNameToken = dbNameToken.replace(/["]/g, '')

            console.log("dbNameToken upload", dbNameToken);
            
            const response = await fetch(url, { method: 'POST', body: formData, headers: { 'DbName':  dbNameToken }});
            const responseData = await response.json();

            if (response.status === 200) {
                console.log("File uploaded successfully", responseData);
                resolve({ status: 200, response });
            } else {
                console.log("File uploaded unsuccessfully", responseData);
                reject({ status: 400, msg: 'Failed to upload file1', error: responseData });
            }
        } catch (error: any) {
            console.log("File uploaded unsuccessfully", 'error');

            reject({ status: 400, msg: 'Failed to upload file2', error: error });
        }
    });
}

//Enterpirse apps  S3 upload 
const S3UploadQuestionnaire_Enterpriseapps = ({ file, fileId, bucketName, folderName }: Props) => {
    console.log("Upload triggered Upload triggered Upload triggered");
    return new Promise(async function (resolve, reject) {
        const formData: any = new FormData();
        formData.append("file", file);
        formData.append("fileId", fileId);
        formData.append("bucketName", bucketName);
        formData.append("folderName", folderName);
        try {
            const url: string = `${process.env.REACT_APP_FLASK_SERVER_URL}/enterpriseapps-questionnaire-uploadfile`;
            let dbNameToken: any = localStorage.getItem(`${STORAGE_KEY}/dbName`);
            console.log("dbNameToken1 upload", dbNameToken);
            dbNameToken = dbNameToken.replace(/["]/g, '')

            console.log("dbNameToken upload", dbNameToken);
            
            const response = await fetch(url, { method: 'POST', body: formData, headers: { 'DbName':  dbNameToken }});
            const responseData = await response.json();

            if (response.status === 200) {
                console.log("File uploaded successfully", responseData);
                resolve({ status: 200, response });
            } else {
                console.log("File uploaded unsuccessfully", responseData);
                reject({ status: 400, msg: 'Failed to upload file1', error: responseData });
            }
        } catch (error: any) {
            console.log("File uploaded unsuccessfully", 'error');

            reject({ status: 400, msg: 'Failed to upload file2', error: error });
        }
    });
}
interface EnterpriseProps {
    file: any;
    bucketName: 'compliance-docs-targhee' | 'mytest1-docs';
    fileId: string;
    vendorName?:string;
    folderName?:string;
    companyId?:string;
}
const S3UploadComplaince_Enterpriseapps = ({companyId, file, fileId, vendorName, bucketName, folderName }: EnterpriseProps) => {
    console.log("Upload triggered Upload triggered Upload triggered");
    return new Promise(async function (resolve, reject) {
        const formData: any = new FormData();
        formData.append("file", file);
        formData.append("fileId", fileId);
        formData.append("vendor_name", vendorName);
        formData.append("bucketName", bucketName);
        formData.append("folderName", folderName);
        formData.append("companyId", companyId);
        console.log("Upload triggeredb");
        try {
            const url: string = `${process.env.REACT_APP_FLASK_SERVER_URL}/enterpriseapps-compliance-uploadfile`;
            let dbNameToken: any = localStorage.getItem(`${STORAGE_KEY}/dbName`);
            console.log("dbNameToken1 upload", dbNameToken);
            dbNameToken = dbNameToken.replace(/["]/g, '')
            
            const response = await fetch(url, { method: 'POST', body: formData, headers: { 'DbName':  dbNameToken }});
            const responseData = await response.json();

            if (response.status === 200) {
                console.log("File uploaded successfully", responseData);
                resolve({ status: 200, response });
            } else {
                console.log("File uploaded unsuccessfully", responseData);
                reject({ status: 400, msg: 'Failed to upload file1', error: responseData });
            }
        } catch (error: any) {
            console.log("File uploaded unsuccessfully", 'error');

            reject({ status: 400, msg: 'Failed to upload file2', error: error });
        }
    });
}


export const hasSubdomain = (hostname: string): boolean => {
    const parts = hostname.split('.');
    // A typical domain has at least two parts (e.g., example.com)
    // If there are more than two parts, it means there's a subdomain (e.g., sub.example.com)
    return parts.length > 2;
};

interface Props2 { fileKey: string, bucketName: string, newFileName?: string, downlodFile?: boolean }

const S3Download = ({ fileKey, bucketName, newFileName, downlodFile = true }: Props2) => {
    console.log(fileKey);
    return new Promise(async function (resolve, reject) {
        try {
            let dbNameToken: any = localStorage.getItem(`${STORAGE_KEY}/dbName`);
            console.log("dbNameToken1", dbNameToken);
            try{
                dbNameToken = dbNameToken.replace(/["]/g, '')
            } catch(e){
            }


            const hostname = window.location.hostname;
            console.log("hostname", hostname);
            if (hasSubdomain(hostname)) {
                dbNameToken = window.location.hostname;
            }
            


            console.log("dbNameToken", dbNameToken);
            const baseUrl = `${process.env.REACT_APP_FLASK_SERVER_URL}/download-docs`;
            const response = await fetch(baseUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'DbName':  dbNameToken 
                },
                body: JSON.stringify({
                    bucketName,
                    fileKey,
                    newFileName
                }),
            });

            const responseData = await response.json();
            if (response.ok) {
                const downloadUrl = responseData.url;
                if (downlodFile === true) {
                    const link: any = document.createElement("a");
                    link.download = newFileName;
                    link.href = downloadUrl;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    link.remove();
                }
                resolve({ status: 200, responseData });
            } else {
                reject({ status: response.status, msg: 'Failed to generate download URL', error: responseData });
            }
        } catch (error) {
            reject({ status: 500, msg: 'Failed to generate download URL', error: error });
        }
    });
}

interface Props3 { fileKey: string, bucketName: string,folderName:string, newFileName?: string, downlodFile?: boolean }
const S3DownloadForEnterprise = ({ fileKey, bucketName, newFileName,folderName, downlodFile = true }: Props3) => {
    console.log(fileKey);
    return new Promise(async function (resolve, reject) {
        try {
            let dbNameToken: any = localStorage.getItem(`${STORAGE_KEY}/dbName`);
            try{
                dbNameToken = dbNameToken.replace(/["]/g, '')
            } catch(e){}

            const hostname = window.location.hostname;
            if (hasSubdomain(hostname)) {
                dbNameToken = window.location.hostname;
            }
            
            const baseUrl = `${process.env.REACT_APP_FLASK_SERVER_URL}/download-docs-enterprise-apps`;
            const response = await fetch(baseUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'DbName':  dbNameToken 
                },
                body: JSON.stringify({
                    bucketName,
                    fileKey,
                    newFileName,
                    folderName
                }),
            });

            const responseData = await response.json();
            if (response.ok) {
                const downloadUrl = responseData.url;
                if (downlodFile === true) {
                    const link: any = document.createElement("a");
                    link.download = newFileName;
                    link.href = downloadUrl;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    link.remove();
                }
                resolve({ status: 200, responseData });
            } else {
                reject({ status: response.status, msg: 'Failed to generate download URL', error: responseData });
            }
        } catch (error) {
            reject({ status: 500, msg: 'Failed to generate download URL', error: error });
        }
    });

    
}


interface Props4 { bucketName: string, fileName: string, fileId:string}
const S3DownloadForLLMQuestionReview = ({bucketName, fileName, fileId}: Props4) => {
    return new Promise(async function (resolve, reject) {
        try {
            let dbNameToken: any = localStorage.getItem(`${STORAGE_KEY}/dbName`);
            try{
                dbNameToken = dbNameToken.replace(/["]/g, '')
            } catch(e){}

            const hostname = window.location.hostname;
            if (hasSubdomain(hostname)) {
                dbNameToken = window.location.hostname;
            }
            
            const baseUrl = `${process.env.REACT_APP_FLASK_SERVER_URL}/download-source-llm-question-review`;
            const response = await fetch(baseUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'DbName':  dbNameToken 
                },
                body: JSON.stringify({
                    bucketName,
                    fileName,
                    fileId
                }),
            });

            const responseData = await response.json();
            if (response.ok) {
                const downloadUrl = responseData.url;
                const link: any = document.createElement("a");
                    link.download = fileName;
                    link.href = downloadUrl;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    link.remove();
                resolve({ status: 200, responseData });
            } else {
                reject({ status: response.status, msg: 'Failed to generate download URL', error: responseData });
            }
        } catch (error) {
            reject({ status: 500, msg: 'Failed to generate download URL', error: error });
        }
    });
}


export { S3Upload, S3Download,S3UploadComplaince_Enterpriseapps, S3DownloadForEnterprise,S3UploadQuestionnaire_Enterpriseapps, S3DownloadForLLMQuestionReview };