import WithSidemenuHeader from './../../Components/WithSidemenuHeader';
import SideMenu from './../../Components/SideMenu';
import React, { useState, ChangeEvent, FormEvent, useEffect, } from 'react'
import './Settings.css'

import { getSettings, savetSettings, updateSettings, getClientDetails, getClientDomainupdate } from './../../ApiServices/SettingsApi'
import { SettingsModel } from './../../Models/SettingsModel';
import { NotificationModel } from '@/Models/SecurityModel';
import { bulkDelete } from '../../ApiServices/TrustCenter';
import { useForm,  } from "react-hook-form";
import { S3Upload, S3Download } from '../../ApiServices/S3Bucket';
import CheckMark from './../../Components/CheckMark';
import { addSubdomain, checkSubdomain } from './../../ApiServices/ValidationApis';
import { toastManager } from './../../Components/ToastDialog/ToastManager';

const Settings = () => {
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
    const [getLogo, setGetLogo] = useState<any>('');
    const [getSetting, setgetSetting] = useState<any>('');
    const [getEmail, setGetEmail] = useState<string>('');
    const [hostDetails, sethostDetails] = useState<any>({});
    const [showUpdateIcon, setShowUpdateIcon] = useState(false);
    const [hostpublish, sethostpublish] = useState<any>('');
    const [errorMsg,  setErrorMsg] =useState<any>('');
    const [icon,  setIcon] =useState<any>('');
    
    // const { control, handleSubmit: handleSubmitForm2, formState: { errors }, setValue, watch: watch, reset } = useForm<SettingsModel>({
    //     items: [{ companyTitle: '', logo: '', createdAt: '', updatedAt: '', _id: '' }]
    // });


    const [notification, setNotification] = useState<NotificationModel>({ type: 'none', msg: '' });

    useEffect(() => {
        getSettingsList();
        getDomainDetails();

    }, []);

    const getDomainDetails = async () => {
        const gres = await getClientDetails();
        console.log('this is gres', gres);
        sethostDetails(gres?.data[0] || {})
        
        try{
            const subdomainName = gres?.data[0]?.cnamepublish
            if (subdomainName) {
                sethostpublish(" is published")
            } else {
                sethostpublish(" is pending")
            }
        } catch (e) {
            console.log('error getting cnamepublish', e)
            sethostpublish(" is pending")
        }
       
    }

    // get method start
    const getSettingsList = async () => {
        const res = await getSettings();
        setgetSetting(res?.data?._id)
        setGetLogo(res?.data?.logo);
        setGetEmail(res?.data?.email);
    }
    // get method end

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result as string);
                convertToPng(reader.result as string, file);
            };
            reader.readAsDataURL(file);
        } else {
            setImagePreviewUrl(null);
        }
    };

    //convert jpg or jpeg into png
    const convertToPng = (dataUrl: string, file: File) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (ctx) {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);

                canvas.toBlob((blob) => {
                    if (blob) {
                        const pngFile = new File([blob], 'linkedin-logo.png', { type: 'image/png' });
                        setSelectedImage(pngFile);
                    }
                }, 'image/png');
            }
        };
        img.src = dataUrl;
    };

    // save method start
    const onFileUpload = async () => {
        if (selectedImage != null) {
            const obj: any = {
                "companyTitle": "sample",
                "logo": selectedImage.name,
            }
            let result: any
            if (getSetting) {
                result = await updateSettings(getSetting, obj);
            } else {
                result = await savetSettings(obj);
            }
            if (result && result.status && result.data) {
                bulkDelete([{ _id: "linkedin-logo.png" }], hostDetails?.fileStorage);
                S3Upload({ 
                    file: selectedImage, 
                    fileId: "linkedin-logo.png", 
                    bucketName: hostDetails?.fileStorage,
                    folderName: '' // Added required folderName prop
                }).then((res: any) => {
                    console.log(res, 's3 upload');
                    setNotification({ type: 'success', msg: 'logo upload successfully' });
                }).catch((err: any) => {
                    console.log(err, 'err file');
                    setErrorMsg('Image uploade fail')
                })

                setShowUpdateIcon(!showUpdateIcon);
                setIcon(showUpdateIcon ? <CheckMark height={20} width={20} color={'success'} /> : <CheckMark height={20} width={20} color={'success'} />);

            } else {
                setNotification({ type: 'error', msg: 'Error:Something went wrong!' });
            }
        }

    }

    const addCname = async (subdomain: string) => {
        const getRes: any = await addSubdomain(subdomain);
        const clinetDomainId = hostDetails?._id

        if (getRes.success  ) {
            sethostpublish(' is published')
            toastManager.show({
                title: 'Site has been published!',
                message: 'Cname record has been created, allow 5 minutes for dns to propagate.',
                type: 'success'
            });

        } else {
            sethostpublish(' error while publishing site')
        }

        // let obj = {
        //     domainPublish: "inprogress"
        // }
        // let cnameCheck: any = await getClientDomainupdate(clinetDomainId, obj)
        // if (cnameCheck.status) {
        //     sethostpublish('inprogress')
        //     //console.log('clinetDomainId', getRes, 'cnameCheck', cnameCheck)
        // }


    }

    const hadleCancel = () => {
        setImagePreviewUrl(null)
    }

    return (
        <main className="acc-main">
            <section className="left-menu-col">
                <SideMenu />
            </section>
            <section className="right-routers-col">
                <WithSidemenuHeader title={'Trust Center Setting'} />
                <div className="router-container ">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="card mt-5" style={{ width: '370px', margin: 'auto' }}>
                                <div className="card-body px-lg-4">
                                    <div>
                                        <h5 className='mt-2 fw-bold text-start'>Logo</h5>
                                        {/* {getLogo} */}
                                        {(imagePreviewUrl || getLogo) ? (
                                            <div>
                                                <img
                                                    src={imagePreviewUrl ? (process.env.PUBLIC_URL + (imagePreviewUrl)) : ("https://" + hostDetails.fileStorage + ".s3.us-east-2.amazonaws.com/" + (getLogo))} className='img-fluid' alt="logo"
                                                    style={{ width: 'auto', maxHeight: '80px' }}
                                                />
                                            </div>
                                        ) : <span style={{ width: '100%', maxHeight: '80px', visibility: 'visible', display: 'inline-block', border: 'solid 1px #eee' }}> </span>}
                                    </div>

                                    <div className='mt-2'>
                                        <input
                                            type="file"
                                            id="upload-logo"
                                            accept=".png, .jpg"
                                            onChange={handleImageChange}
                                            placeholder='file upload'
                                        />

                                        <button onClick={() => document.getElementById('upload-logo')?.click()} type="submit" className='file_custom-file file_btn file_btn-bg'><img src={process.env.PUBLIC_URL + 'assets/imgs/upload-img.png'} alt="upload icon" /> Upload</button>
                                        <button className='cancel-btn ms-2' onClick={hadleCancel}> Cancel </button>
                                        <button className='save-btn ms-3' onClick={() => onFileUpload()}>  Save</button>
                                        <span style={{ position: 'relative', top: '25px', left: '8px' }}> {icon}</span>

                                        <div className='text-warning'>{errorMsg}</div>
                                    </div>


                                    <div>
                                        <h5 className='mt-5 fw-bold'>Trust Center URL</h5>
                                        <p>
                                            <a href="#" className="fs-8 text-decoration-none fw-normal gray-color">
                                                {`${hostDetails?.cname}.targheesec.ai`}
                                            </a>
                                            {hostpublish}
                                        </p>
                                        {/* <p><a href="#" className='fs-8 text-decoration-none fw-normal gray-color'>{`${hostDetails?.cname}.targheesec.ai`}</a> {hostpublish == 'pending' ? ' is not published' : (hostpublish == 'inprogress' ? 'Site is being published' : 'Site is published')}  </p> */}
                                    </div>
                                    {hostpublish == ' is pending' ?
                                        (<div className='text-center'>
                                            <button className='btn-warning btn ms-3 px-4' onClick={() => addCname(hostDetails?.cname)}>Publish</button>
                                        </div>) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Settings;