import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './LoginPage.css';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { setAuthData, setThemeColors } from './../../Redux/Actions/SagaAction';
import Validate from './../../Helper/ValidationUtils';

function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errPwd, setErrors] = useState<any>([]);
    const [emailError, setEmailErrors] = useState<any>('');
    const [passwordError, setPasswordError] = useState<any>('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /**
     * Handles key press events. If the Enter key is pressed, it shows the password.
     * @param e - The keyboard event object.
    */
    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (!username) {
                setEmailErrors('Please enter your email address.');
            } else if (!Validate.Email(username)) {
                setEmailErrors('Please enter valid email.');
                setShowPassword(false);
                setPassword('');
            } else {
                setEmailErrors('');
                setShowPassword(true);
            }
        }
    };

    /**
     * Handles key press events. If the Enter key is pressed, it shows the password.
     * @param e - The keyboard event object.
    */
    const handleKeyPressPassword = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (!password) {
                setPasswordError('Please enter your password.');
            } else {
                setPasswordError('');
            }
        }else if(password){
            setPasswordError('');
        }
    };

    /**
     * Handles form submission for login. It sends a POST request to the login endpoint,
     * processes the response, updates local storage, and navigates to another route on success.
     * @param event - The form submit event object.
    */
    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!username) return;
        const pass:string=(password ? password.trim() : '');
        if (!pass) {
            setPasswordError('Please enter your password.');
            return;
        };
        if (!Validate.Email(username)) {
            setEmailErrors('Please enter valid email.');
            return;
        };
        setPasswordError('');
        setEmailErrors('');

        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: username, password }),
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/userlogin`, options);
            if (response.status === 200) {
                const result = await response.json();
                await setLocalStorage(`${process.env.REACT_APP_STORAGE_KEY}/authData`, result.data);
                await setLocalStorage(`${process.env.REACT_APP_STORAGE_KEY}/dbName`, result.data.dbName);
                await setLocalStorage(`${process.env.REACT_APP_STORAGE_KEY}/token`, result.data.token);
                dispatch(setAuthData(result.data));
                if(result?.themesettings && result?.themesettings?.headerColor){
                    dispatch(setThemeColors({
                        _id:result.themesettings._id,
                        headerColor:result.themesettings.headerColor,
                        backgroundColor:result.themesettings.backgroundColor,
                        textColor:result.themesettings.textColor,
                        buttonColor:result.themesettings.buttonColor,
                    }));
                }
               
                navigate("/enterprise");
            } else if (response.status === 400) {
                SignInFailed();
            }
        } catch (error) {
            SignInFailed();
            console.error('There was an error!', error);
        }
    };

    /**
     * Sets an item in local storage.
     * @param key - The key under which the item will be stored.
     * @param value - The value to be stored.
     * @returns A promise that resolves when the item is successfully set.
     */
    async function setLocalStorage(key: string, value: any): Promise<void> {
        return new Promise<void>((resolve) => {
            localStorage.setItem(key, JSON.stringify(value));
            resolve();
        });
    }

    /**
     * Handles failed sign-in attempts. Sets an error message and clears it after 3 seconds.
     */
    const SignInFailed = () => {
        setErrors(['Invalid username or password']);
        // Clear error message after 3 seconds
        setTimeout(() => {
            setErrors([]);
        }, 3000); // 3000 milliseconds = 3 seconds
    };

    /**
     * Validates the provided password. Checks if the password is empty and sets error messages accordingly.
     * @param password - The password to validate.
     * @returns An array of error messages.
     */
    const validatePassword = (password: string): string[] => {
        setPassword(password);
        const errors: string[] = [];
        if (!password) {
            // errors.push('This field is required');
        }
        setErrors(errors);
        return errors;
    };


    // input required text 
    document.addEventListener('invalid', (function () {
        return function (e) {
            e.preventDefault();
            document.getElementById("passwords")?.focus();
        };
    })(), true);

    return (
        <main className='homepage-container'>
            <div className="container">
                <img src={process.env.PUBLIC_URL + 'assets/imgs/watermark.png'} alt="watermark" className="watermark" />
                <div className="row ">
                    <div className="col-lg-6 offset-lg-3 ">
                        <div className="homepage-section">
                            <div className="section-wrapper">
                                <img src={process.env.PUBLIC_URL + 'assets/imgs/logo.png'} alt="Targhee security" className="logo-img" />
                                <h1 className='message'>Sign in with Targhee ID</h1>
                                <form className="form-container" onSubmit={handleLogin}>

                                    <div className="form-item">
                                        <input
                                            type="text"
                                            value={username}
                                            autoFocus
                                            onChange={(e) => setUsername(e.target.value)}
                                            className="input"
                                            autoComplete="off"
                                            id="Email-address"
                                            onKeyPress={handleKeyPress}
                                            required
                                        />
                                        <label htmlFor="Email-address">Email address*</label>
                                        {emailError ? (
                                            <div>
                                                <small className='text-danger'>{emailError}</small>
                                            </div>
                                        ) : null}
                                    </div>

                                    {showPassword && (
                                        <div style={{ width: '100%' }}>
                                            <div className="form-item">
                                                <input
                                                    type="password"
                                                    autoFocus
                                                    value={password}
                                                    onChange={(e) => validatePassword(e.target.value)}
                                                    autoComplete="off"
                                                    id="passwords"
                                                    required={true}
                                                    onKeyDown={handleKeyPressPassword}
                                                />
                                                <label htmlFor="passwords">Password*</label>
                                                <button style={{ position: 'absolute', top: '12px', right: '10px' }} type="submit" className="next-button2">
                                                    <FontAwesomeIcon icon={faChevronRight} size="1x" className="white-icon" />
                                                </button>

                                                {passwordError  ?(
                                                    <div>
                                                        <small className='text-danger'>{passwordError}</small>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    )}
                                </form>

                                {errPwd && (
                                    <div style={{ color: 'red' }}>{errPwd}</div>
                                )}
                            </div>
                            <div className="links-container">
                                <p className='signup-link'>Don't have an account? <Link className="text-decoration-none" to={'/signup'}>Sign up</Link></p>
                                <Link to='/forgot-password' className='forgotpass-link'>Forgot Password</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default LoginPage;