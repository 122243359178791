import React, { useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import { getAuth } from './../../ApiServices/Auth.Service';
import './SideMenu.css';

const SideMenu = () => {
    const [isAdminLogin, setAdminLogin] = useState<boolean>(false);

    useEffect(() => {
        setAdminLogin(getAuth()?.admin);
    }, []);

    return (
        <div className="left-menu-main">
            <div className="left-menu">
                <div className="logo">
                    <img src={process.env.PUBLIC_URL + '/assets/imgs/targhee-logo.png'} className='img-fluid' alt="logo" />
                </div>

                <div className="menu-links">
                    <ul className='list-unstyled'>
                        <li>
                            <NavLink to="/enterprise" className={({ isActive }) => (isActive ? "menu-active" : "")}>Security Questionnaire</NavLink>
                        </li>

                        <li>
                            <NavLink to="/trust-center-editor" className={({ isActive }) => (isActive ? "menu-active" : "")}>Trust Center Editor</NavLink>
                        </li>
                        <li>
                            <NavLink to="/trust-center" target="_blank" rel="noopener noreferrer" className={({ isActive }) => (isActive ? "menu-active" : "")}>Trust Center Preview</NavLink>
                        </li>
                        <li>
                            <NavLink to="/account" className={({ isActive }) => (isActive ? "menu-active" : "")}>Access Requests</NavLink>
                        </li>



                        {/* <li>
                        <NavLink to="/llm-question-review" className={({ isActive }) => (isActive ? "menu-active" : "")}>LLM Question Review</NavLink>

                        </li>  */}

                        {isAdminLogin ? (
                            <li>
                                <NavLink to="/people" className={({ isActive }) => (isActive ? "menu-active" : "")}>People</NavLink>
                            </li>
                        ) : (
                            <li className="empty-link">  </li>
                        )}
                        <hr className='mt-5' />
                        <li>
                            <NavLink to="/settings" className={({ isActive }) => (isActive ? "menu-active" : "")}>Settings</NavLink>
                        </li>
                        <li>
                            <NavLink to="/NDA/addNDA" className={({ isActive }) => (isActive ? "menu-active" : "")}>NDA</NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/help-center" className={({ isActive }) => (isActive ? "menu-active" : "")}>Help Center</NavLink>
                        </li>
                        <li>
                            <NavLink to="/api-guide" className={({ isActive }) => (isActive ? "menu-active" : "")}>API Guide</NavLink>
                        </li> */}
                    </ul>
                </div>

                <div className='footre-lnks'>
                    <Link to="/terms-of-service" target='_blank'>Terms of Service</Link>
                    <Link to="/privacy-policy" target='_blank'>Privacy Policy</Link>
                    <p>Copyrights 2025 Targhee Security</p>
                </div>
            </div>
        </div>
    );
}

export default SideMenu;

{/* <li className="hover-tooltip">
    <a href="#">Conversations</a>
    <span className="tooltip-text">Coming soon</span>
</li>
<li className='hover-tooltip' >
    <a href="#">AI Questionnaire Complete</a>
    <span className="tooltip-text">Coming soon</span>
</li> */}